import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import SubmitButton from "../Common/buttons/SubmitButton";
import { ApiHook } from "../../hooks/apiHook";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../store/reducers/userReducer";
import { useQueryClient } from "@tanstack/react-query";
import Select from 'react-select'

const ProfileDetailsTab = ({ occupationList, educationList }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const profileData = useSelector(
    (state) => state?.user?.profile?.personalDetails
  );
  console.log("profileData ==== ", profileData);
  const {
    register,
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = useForm();
  const formValues = watch();
  const [isEditModeEnabled, setIsEditModeEnabled] = useState(false);
  const updateMutation = ApiHook.CallUpdatePersonalDetails();
  const toggleEditMode = () => {
    setIsEditModeEnabled(!isEditModeEnabled);
  };
  const genderOptions = [
    { value: "M", label: `${t("male")}` },
    { value: "F", label: `${t("female")}` },
    { value: "O", label: `${t("other")}` },
  ]

  const changeGender = (gender) => {
    setValue("gender", gender?.value)
  }
  const changeEducation = (data) => {
    setValue("education", data?.value)
  }
  const changeOccupation = (data) => {
    setValue("occupation", data?.value)
  }

  useEffect(() => {
    if (profileData) {
      setValue("name", profileData.name);
      setValue("secondName", profileData.secondName);
      setValue("gender", profileData.gender);
      setValue("education", profileData.education);
      setValue("occupation", profileData.occupation);
      setValue("otherEducation", profileData.otherEducation);
      setValue("otherOccupation", profileData.otherEducation);


    }
  }, [profileData, setValue]);

  const onSubmit = async () => {
    const isValid = await trigger()
    if (isValid) {
      updateMutation.mutate(formValues, {
        onSuccess: (res) => {
          if (res.status) {
            dispatch(
              updateProfile({
                profileDetails: formValues,
              })
            );
            queryClient.invalidateQueries({ queryKey: ["profile"] })
            toast.success(res?.data);
            setIsEditModeEnabled(false)
          }
        },
      });
    }
  };
  return (
    <div id="firstTab" className="tabcontent">
      <div className="editSec">
        <div className="editBg">
          <span
            style={{ textDecoration: "none", cursor: "pointer" }}
            onClick={toggleEditMode}
          >
            <i
              className="fa-solid fa-pen-to-square"
              style={{ color: "#32009c" }}
            ></i>
          </span>
        </div>
      </div>
      <h3>{t("personalDetails")}</h3>
      <div className="tabcontent_form_section">
        <div className="mb-3 row tabBlockClass">
          <label
            htmlFor="name"
            className="col-sm-3 col-form-label labelWidthClass"
          >
            {t("firstName")}:
          </label>
          <div className="col-md-9 col-sm-12 col-12">
            <input
              {...register("name", {
                required: t("this_field_is_required"),
                pattern: {
                  value: /^[A-Za-z0-9]+$/,
                  message: t("invalid_format"),
                },
              })}
              defaultValue={profileData?.name}
              type="text"
              id="name"
              className="form-control"
              disabled={!isEditModeEnabled}
            />
            {errors.name && (
              <span className="validation-error-message">
                {errors.name.message}
              </span>
            )}
          </div>
        </div>
        <div className="mb-3 row tabBlockClass">
          <label
            htmlFor="secondName"
            className="col-sm-3 col-form-label labelWidthClass"
          >
            {t("lastName")}:
          </label>
          <div className="col-md-9 col-sm-12 col-12">
            <input
              {...register("secondName", {
                pattern: {
                  value: /^[A-Za-z0-9]+$/,
                  message: t("invalid_format"),
                },
              })}
              defaultValue={profileData?.secondName}
              type="text"
              id="secondName"
              className="form-control"
              disabled={!isEditModeEnabled}
            />
            {errors.secondName && (
              <span className="validation-error-message">
                {errors.secondName.message}
              </span>
            )}
          </div>
        </div>
        <div className="mb-3 row tabBlockClass">
          <label
            htmlFor="3"
            className="col-sm-3 col-form-label labelWidthClass"
          >
            {t("gender")}:
          </label>
          <div className="col-md-9 col-sm-12 col-12">
            <Select
              {...register("gender")}
              id="3"
              value={genderOptions.find((item) => item.value === formValues?.gender)}
              isDisabled={!isEditModeEnabled}
              isSearchable={false}
              options={genderOptions}
              onChange={changeGender}
            />
          </div>
        </div>
        {/* ========================================== */}
        <div className="mb-3 row tabBlockClass">
          <label
            htmlFor="3"
            className="col-sm-3 col-form-label labelWidthClass"
          >
            {t("education")}:
          </label>
          <div className="col-md-9 col-sm-12 col-12">
            <Select
              {...register("education")}
              id="3"
              value={educationList.find((item) => item.value === formValues?.education)}
              isDisabled={!isEditModeEnabled}
              isSearchable={false}
              options={educationList}
              onChange={changeEducation}
            />
          </div>
        </div>
        {educationList.find((item) => item.label === "Others")?.value === formValues.education &&
          <div className="mb-3 row tabBlockClass">
            <label
              htmlFor="otherEducation"
              className="col-sm-3 col-form-label labelWidthClass"
            >
              {t("otherEducation")}:
            </label>
            <div className="col-md-9 col-sm-12 col-12">
              <input
                {...register("otherEducation", {
                  required: t("this_field_is_required"),
                  // pattern: {
                  //   value: /^[A-Za-z0-9]+$/,
                  //   message: t("invalid_format"),
                  // },
                })}
                defaultValue={""}
                type="text"
                id="otherEducation"
                className="form-control"
                disabled={!isEditModeEnabled}
              />
              {errors.otherEducation && (
                <span className="validation-error-message">
                  {errors.otherEducation.message}
                </span>
              )}
            </div>
          </div>
        }

        <div className="mb-3 row tabBlockClass">
          <label
            htmlFor="4"
            className="col-sm-3 col-form-label labelWidthClass"
          >
            {t("occupation")}:
          </label>
          <div className="col-md-9 col-sm-12 col-12">
            <Select
              {...register("occupation")}
              id="5"
              value={occupationList.find((item) => item.value === formValues?.occupation)}
              isDisabled={!isEditModeEnabled}
              isSearchable={false}
              options={occupationList}
              onChange={changeOccupation}
            />
          </div>
        </div>
        {occupationList.find((item) => item.label === "Other")?.value === formValues.occupation &&
          <div className="mb-3 row tabBlockClass">
            <label
              htmlFor="otherOccupation"
              className="col-sm-3 col-form-label labelWidthClass"
            >
              {t("otherOccupation")}:
            </label>
            <div className="col-md-9 col-sm-12 col-12">
              <input
                {...register("otherOccupation", {
                  required: t("this_field_is_required"),
                  // pattern: {
                  //   value: /^[A-Za-z0-9]+$/,
                  //   message: t("invalid_format"),
                  // },
                })}
                defaultValue={""}
                type="text"
                id="otherOccupation"
                className="form-control"
                disabled={!isEditModeEnabled}
              />
              {errors.otherOccupation && (
                <span className="validation-error-message">
                  {errors.otherOccupation.message}
                </span>
              )}
            </div>
          </div>
        }
        {/* ========================================== */}


        <div
          className={`paymenytLinkBtn ${isEditModeEnabled ? "disabled" : ""}`}
        >
          <SubmitButton
            isSubmitting={!isEditModeEnabled}
            className="btn"
            text="update"
            click={onSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default ProfileDetailsTab;
